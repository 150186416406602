import debounce from "lodash.debounce";
import { Folder, Note, UserSettings } from "../../../shared/types";
import { Auth } from "../../auth/auth";
import { IdeaflowUser } from "../../auth/useAuth";
import {
  ClientToSWMessageType,
  SWToClientMessage,
  SWToClientMessageType,
} from "../../service-worker/message/swMessage";
import { isLocal, modelVersion } from "../../utils/environment";
import logger from "../../utils/logger";
import { generateId } from "../generateId";
import RamDirtySet from "../sync/RamDirtySet";
import { getSyncStats, SyncStatsUpdate } from "../sync/SyncStats";
import { sendMessageToSW, sendMessageToSWWhenReady } from "../sync/sendMessageToSW";

export const userDirtyId = "user-settings";

export class LocalStore {
  private dirtySet: RamDirtySet;

  public refreshEditor: ReturnType<typeof debounce>;
  public markDataAsLoaded: () => void;
  public isLoaded: boolean;

  public sendFreshTokenToSW: () => Promise<void>;

  private ackIdCallbacks: Map<string, (value: any) => void>;

  public noteHandler: (note: Partial<Note> & { id: string }) => void;
  public folderHandler: (folder: Partial<Folder> & { id: string }) => void;
  public userSettingsHandler: (settings: UserSettings) => void;
  public onSyncStatsUpdate: (update: SyncStatsUpdate) => void;

  private mostRecentUpdatedAt: number = 0;

  constructor(dirtySet?: RamDirtySet) {
    this.dirtySet = dirtySet ?? new RamDirtySet();

    this.refreshEditor = debounce(() => {});
    this.markDataAsLoaded = () => {};
    this.isLoaded = false;

    this.sendFreshTokenToSW = () => Promise.resolve();

    this.ackIdCallbacks = new Map();

    this.noteHandler = () => {};
    this.folderHandler = () => {};
    this.userSettingsHandler = () => {};
    this.onSyncStatsUpdate = () => {};
  }

  setAuth(accessToken: string, user: IdeaflowUser) {
    logger.info("sending auth to sw", {
      namespace: "sync-main",
    });
    sendMessageToSW({
      type: ClientToSWMessageType.AUTH,
      auth: { type: "logged-in", accessToken, user },
    });
  }

  loadAllData(modelVersion: string) {
    logger.info("sending load to sw", {
      namespace: "sync-main",
    });
    sendMessageToSW({
      type: ClientToSWMessageType.LOAD,
      modelVersion,
    });
  }

  syncAllData(modelVersion: string) {
    logger.info("sending sync to sw", {
      namespace: "sync-main",
    });
    sendMessageToSW({
      type: ClientToSWMessageType.SYNC,
      modelVersion,
    });
  }

  upsertUserSettings(userSettings: any, transactionId: number, modelVersion: string): void {
    logger.info("sending user settings upsert to sw", {
      namespace: "sync-main",
      context: {
        transactionId,
      },
    });
    sendMessageToSW({
      type: ClientToSWMessageType.UPSERT,
      userSettings,
      transactionId,
      modelVersion,
    });
  }

  upsertFolders(folders: Folder[]): void {
    // Check metadata to keep track of sync status
    const mostRecentUpdate = folders
      .map((f) => (f.deletedAt && f.deletedAt > f.updatedAt ? f.deletedAt : f.updatedAt))
      .reduce((prevMax, updateTs) => (updateTs > prevMax ? updateTs : prevMax), new Date(0))
      .getTime();
    if (mostRecentUpdate > this.mostRecentUpdatedAt) {
      this.mostRecentUpdatedAt = mostRecentUpdate;
    }

    const transactionId = this.dirtySet.startUpsert(folders.map((f) => f.id));
    logger.info("sending folders upsert to sw", {
      namespace: "sync-main",
      context: {
        ids: folders.map((n) => n.id),
        transactionId,
      },
    });
    sendMessageToSW({
      type: ClientToSWMessageType.UPSERT,
      folders,
      transactionId,
      modelVersion,
    });
  }

  upsertNotes(notes: Note[]): void {
    // Check metadata to keep track of sync status
    const mostRecentUpdate = notes
      .map((n) => (n.deletedAt && n.deletedAt > n.updatedAt ? n.deletedAt : n.updatedAt))
      .reduce((prevMax, updateTs) => (updateTs > prevMax ? updateTs : prevMax), new Date(0))
      .getTime();
    if (mostRecentUpdate > this.mostRecentUpdatedAt) {
      this.mostRecentUpdatedAt = mostRecentUpdate;
    }

    const transactionId = this.dirtySet.startUpsert(notes.map((n) => n.id));
    logger.info("sending upsert to sw", {
      namespace: "sync-main",
      context: {
        ids: notes.map((n) => n.id),
        transactionId,
      },
    });
    sendMessageToSW({
      type: ClientToSWMessageType.UPSERT,
      notes,
      transactionId,
      modelVersion,
    });
  }

  handleSyncData(e: MessageEvent<SWToClientMessage>): void {
    const msgType = e.data.type;
    logger.info(`handling ${msgType} message from sw`, {
      namespace: "sw-message",
    });

    if (msgType === SWToClientMessageType.ACKNOWLEDGE) {
      // TODO: clean this up
      // Implementation with callback map was carried over from old handleSwMessage code but we can do better
      const callback = this.ackIdCallbacks.get(e.data.ackId);
      if (!callback) {
        logger.warn("Nothing waiting for the ack message :(");
        return;
      }
      callback(e.data.ok);
      return;
    }

    if (msgType === SWToClientMessageType.ERROR) {
      // TODO: This was disabled b/c it's spamming Jacob making the app unusable. I *think* a recent change is
      // causing false positives, which is why I'm disabling, but we should fix it. See ENT-4045
      // setTimeout(() => {
      //   const syncStats = getSyncStats();
      //   throttledAddSyncIssueToast(syncStats.sw?.dirtyNoteIds.size ?? 0);
      // }, 1000);
      // logger.fatal("USER SAW BIG RED ERROR", { context: { errorMessage: e.data.message }, namespace: "sw-message" });
      if (isLocal) {
        throw new Error(`Service worker error: ${e.data.message}\nSee console for more details.`);
      }
      return;
    }

    if (msgType === SWToClientMessageType.REQUEST_ACCESS) {
      this.sendFreshTokenToSW();
      return;
    }

    // When a new client is loaded, an old service worker may initially be handling
    // messages. All the message types below must be handled by the latest service
    // worker, so we ignore them if the model version doesn't match.
    const swModelVersion: string | undefined = (e.data as any)?.modelVersion;
    if (swModelVersion !== modelVersion) {
      logger.warn(`Ignoring ${msgType} message, model version mismatch`, {
        context: {
          client: modelVersion,
          sw: swModelVersion,
        },
      });
    } else if (msgType === SWToClientMessageType.SYNC_STATS) {
      this.onSyncStatsUpdate(e.data.syncStats);
    } else if (msgType === SWToClientMessageType.UPSERT_ACKNOWLEDGE) {
      const transactionId = e.data.transactionId;
      const noteIds = e.data.noteIds ?? [];
      const folderIds = e.data.folderIds ?? [];
      const userSettings = e.data.userSettings;
      logger.info(`received from sw upsert ack trx ${transactionId}`, {
        namespace: "sync-main",
        context: { noteIds, folderIds, userSettings, transactionId },
      });
      // If the note is echoed back from the sw, we can stop tracking it as dirty.
      noteIds.forEach((noteId) => this.dirtySet.stopUpsert(noteId, transactionId));
      folderIds.forEach((folderId) => this.dirtySet.stopUpsert(folderId, transactionId));
      if (userSettings) this.dirtySet.stopUpsert(userDirtyId, transactionId);
    } else if (msgType === SWToClientMessageType.UPSERT || msgType === SWToClientMessageType.LOAD_RESPONSE) {
      // This is a critical path in data flow
      // This is where the main thread gets sent the data that was loaded in from elsewhere
      // Can/should go in LocalStore
      if (msgType === SWToClientMessageType.UPSERT && !this.isLoaded) {
        return;
      }

      const { notes, folders, userSettings } = e.data;
      logger.info(`received ${msgType} from sw`, {
        namespace: "sync-main",
        context: {
          notes: notes?.map((n) => n.id) ?? [],
          folders: folders?.map((f) => f.id) ?? [],
        },
      });

      let hasTokens = false;
      notes?.forEach((note) => {
        if (this.dirtySet.has(note.id)) return;
        this.noteHandler(note);
        if (note.tokens) {
          hasTokens = true;
        }
      });

      folders?.forEach((folder) => {
        if (this.dirtySet.has(folder.id)) return;
        this.folderHandler(folder);
      });

      if (userSettings && !this.dirtySet.has(userDirtyId)) {
        this.userSettingsHandler(userSettings);
      }

      // markDataAsLoaded already refreshes the editor so there is no need to refresh it again
      if (msgType === SWToClientMessageType.LOAD_RESPONSE) {
        this.markDataAsLoaded();
      } else if (hasTokens) {
        this.refreshEditor();
      }
    }
  }

  hasUnsyncedData(): boolean {
    const swSyncStats = getSyncStats();
    const swNotSyncedWithServer = swSyncStats.countSinceLastFullySynced > 0;
    const clientNotSyncedWithSw =
      this.mostRecentUpdatedAt > swSyncStats.updatedAt || this.dirtySet.listIds().length > 0;
    return swNotSyncedWithServer || clientNotSyncedWithSw;
  }

  async logout() {
    const auth: Auth = { type: "logged-out" };
    const ackId = generateId();
    const ackPromise = new Promise<void>((res) => this.ackIdCallbacks.set(ackId, res));
    await sendMessageToSWWhenReady({ type: ClientToSWMessageType.AUTH, auth, ackId });

    // Wait until the SW acknowledges the message was handled or timeout happens
    await Promise.race([ackPromise, timeout(120_000, `sendAuth(${JSON.stringify(auth)})`)]);
  }

  async sendDeleteAccountAndAwaitAck() {
    const ackId = generateId();
    const ackPromise = new Promise<void>((res) => this.ackIdCallbacks.set(ackId, res));
    await sendMessageToSWWhenReady({ type: ClientToSWMessageType.DELETE_ACCOUNT, ackId });
    const timeoutPromise = new Promise<boolean>((res) => setTimeout(() => res(false), 30_000));
    return await Promise.race([ackPromise, timeoutPromise]);
  }

  enableDebug(namespaces: string): void {
    sendMessageToSWWhenReady({ type: ClientToSWMessageType.DEBUG, namespaces });
  }

  debugErrorTest(): void {
    sendMessageToSW({
      type: ClientToSWMessageType.ERROR_TEST,
      error: "Test error from SW. Ignore this.",
    });
  }

  debugReset(): void {
    sendMessageToSW({ type: ClientToSWMessageType.RESET });
  }
}

const timeout = (ms: number, operationName = "unknown") =>
  new Promise((_res, rej) => setTimeout(() => rej(new Error(`Operation timed out: ${operationName}`)), ms));
